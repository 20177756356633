<template>
    <div class="card-contents">
        <h2>Module <span v-if="module">{{module.name}} {{module.variant}} {{module.version}}</span></h2>

        <div v-for="page in module.pages">
            <table class="table">
                <thead>
                    <tr><th></th><th>{{page.title}}</th><th>Type</th></tr>
                </thead>
                <tbody>
                    <tr v-for="(question, index) in page.questions">
                        <td v-if="false && editMode">
                            <span v-if="index > 0" class="righttop-icon up-arrow" @click="moveUp(question.id, module.id)"></span>
                            <span v-if="index < page.questions.length -1" class="righttop-icon down-arrow" @click="moveDown(question.id, module.id)"></span>
                        </td>
                        <td>{{index+1}}</td>
                        <td>
                            <span
                                v-if="subjectId != question.id"
                                @click="editCompetence(question.id)"
                                ref="editTextContainer"
                            >
                                {{question.question}}
                            </span>
                            <text-edit
                                v-if="subjectId == question.id"
                                :text="question.question"
                                @editCancelled = "editCancelled"
                                @valueChanged = "valueChanged"
                            >
                            </text-edit>
                        </td>
                        <td>
                            <dropdown-edit
                                v-if="editMode"
                                :baseValue="textVal(question)"
                                :options="typeOptions"
                                :competenceId="question.id"
                                @typeChanged = "typeChanged"
                            ></dropdown-edit>
                            <div v-if="!editMode">
                                <span>{{textVal(question)}}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <edit-mode v-if="canEdit" @editModeChanged="changeEditMode" :checked="editMode"></edit-mode>
    </div>
</template>

<script>
export default {
    name: "Modules",
    data() {
        return {
            module: null,
            errors: [],
            editMode: false,
            subjectId: null,
            typeOptions: ['Attitude/Vaardigheden', 'Kennis'],
            canEdit: false
        }
    },

    mounted() {
        this.getModule();
    },

    props: ['baseUrl', 'moduleId'],

    methods: {
        textVal: function (competence) {
            if (competence.weight_attitude && !competence.weight_knowledge) {
                return 'Attitude/Vaardigheden';
            } else if(!competence.weight_attitude && competence.weight_knowledge) {
                return 'Kennis';
            } else {
                return 'Fout';
            }
        },
        editCompetence(which) {
            if (this.editMode && !this.subjectId) {
                this.subjectId = which;
            }
        },
        changeEditMode: function(newEditMode) {
            this.editMode = newEditMode;
        },
        editCancelled() {
            this.subjectId = null;
        },
        valueChanged: function(newValue) {
            const data = {name: newValue}
            axios.patch('/api/competences/'+this.subjectId, data)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.updateCompetence(response.data.competence);
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            this.subjectId = null;
        },
        typeChanged: function(newValue, competenceId) {
            const data = {type: newValue}
            axios.patch('/api/competences/'+competenceId, data)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.updateCompetence(response.data.competence);
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            this.subjectId = null;
        },
        updateCompetence(updatedCompetence) {
            this.getModule();
        },
        getModule() {
            axios.get('/api/module/'+this.moduleId)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.module = response.data.data;
                        this.canEdit = response.data.canEdit;
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        moveUp(questionId, moduleId) {
            axios.post('/api/modules/'+this.moduleId+'/competences/'+questionId+'/moveUp')
                .then(response => {
                    if (response.data.result == 'success') {
                        this.updateOrder(questionId)
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        moveDown(questionId, moduleId) {
            axios.post('/api/modules/'+this.moduleId+'/competences/'+questionId+'/moveDown')
                .then(response => {
                    if (response.data.result == 'success') {
                        this.module = response.data.data;
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        updateOrder(){

        }
    }
}
</script>

<style scoped>
    .down-arrow{
        background-image: url('~bootstrap-icons/icons/arrow-down-square-fill.svg');
    }
    .up-arrow{
        background-image: url('~bootstrap-icons/icons/arrow-up-square-fill.svg');
    }
</style>
